<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:800px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- <h1 class="page_title">{{ popName }}</h1> -->
      <div class="content_box">
        <select v-model="atchFileSeq" name="fileSeq" id="fileSeq" class="mb10" @change="getChangeFile()">
          <option v-for="vo in fileList" :key="vo.atchFileSeq" :value="vo.atchFileSeq">{{ vo.fileNm }}</option>
        </select>
        <div class="clearfix">
          <div class="float_left text_center col_10 pl05" style="max-height:600px;overflow-y:auto">
            <vue-pdf-app
              v-if="fileType == 'PDF'"
              style="height: 600px;"
              :pdf="fileUrl"
              :config="pdfConfig.config"
              theme="light"
            ></vue-pdf-app>
            <img v-else-if="fileType == 'IMG'" :src="fileUrl">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import myRequest from '@/api/rest/trans/myRequest'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  name: 'MyRequestDocPop',
  components: {
    vuePdfApp: () => import('vue-pdf-app')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      popName: '',
      fileUrl: '',
      fileType: '',
      fileList: [],
      atchFileSeq: '',
      pdfConfig: {
        config: {},
        idConfig: {}
      }
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.pdfConfig = this.getPdfConfig()
      if (this.$props.parentInfo !== undefined) {
        this.setItems(this.$props.parentInfo)
      }
    },
    async setItems (rvo) {
      // if (rvo.reqBizCatCd === 'RE01') {
      //   this.popName = 'Booking Cancel'
      // } else if (rvo.reqBizCatCd === 'RE02') {
      //   this.popName = 'Shuttle'
      // } else if (rvo.reqBizCatCd === 'RE14') {
      //   this.popName = 'Ship-back'
      // } else if (rvo.reqBizCatCd === 'RE15') {
      //   this.popName = 'COD'
      // } else if (rvo.reqBizCatCd === 'RE16') {
      //   this.popName = 'Carrying out Full Container'
      // } else if (rvo.reqBizCatCd === 'RE17') {
      //   this.popName = 'Container No. Correction Request'
      // }

      await myRequest.getMyRequestFile({ reqRno: rvo.reqRno }).then(async res => {
        if (res.data.docFileList) {
          const list = res.data.docFileList
          const vo = list[0]
          if (!vo) {
            await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CMBA380.023') })
            this.$emit('close')
          } else {
            this.atchFileSeq = vo.atchFileSeq
            if (vo.fileNm.toLowerCase().indexOf('.pdf') > -1) {
              this.fileType = 'PDF'
            } else {
              this.fileType = 'IMG'
            }
            this.fileUrl = await this.setUrl(vo.fileCont)
            this.fileList = list
          }
        }
      })
    },
    setUrl (fileCont) {
      let image = new Image()
      image.src = 'data:image/png;base64,' + fileCont
      return image.src
    },
    getChangeFile () {
      this.fileList.forEach((obj) => {
        if (obj.atchFileSeq === this.atchFileSeq) {
          this.fileUrl = this.setUrl(obj.fileCont)
          return false
        }
      })
    },
    getPdfConfig () {
      const config = { toolbar: false }
      return {
        config
      }
    }
  }
}
</script>

<style>
body {
  min-width:100% !important;
}
</style>
